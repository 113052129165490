/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import { debounce } from 'lodash'

// define a mixin object
export const trackingMixin = {
  // * WAS PLANNING TO PUT IT ON router/main.js BUT IT KEEPS ADDING NEW LISTENER ON EVERY PAGE NAVIGATION 
  data() {
    return {
      firstPageVisit: true,
      clickEvent: null,
      scrollEvent: null,
    }
  },
  watch: {
    '$route.name': function () {
      this.initScrollListener()
      this.initClickListener()

      if (! this.firstPageVisit) {
        this.trackPageVisit()
      }
    }

  },
  created() {
    this.trackPageVisit()

    this.initScrollListener()
    this.initClickListener()
  },

  methods: {
    initScrollListener() {
      window.removeEventListener('scroll', event => {})
      setTimeout(() => {
        window.addEventListener('scroll', debounce(event => {
          const form_data = {
            page_url: this.$removeUtmQuery(window.location.href),
            route_name: this.$route.name,
            tracking_id: localStorage.getItem('tracking_id'),
            type: 'scroll',
            meta: {
              client_x: event.clientX,
              client_y: event.clientY,
            },
          }
    
          this.$http
            .post(`/api/page-activity-tracker`, { form_data })
            .then(response => {
              const { success, output, message } = response.data
            })
            .catch(error => {
              //
            })
        }, 1000))
      }, 50)
    },

    initClickListener() {
      window.removeEventListener('click', event => {})
      setTimeout(() => {
        window.addEventListener('click', debounce(event => {
          const form_data = {
            page_url: this.$removeUtmQuery(window.location.href),
            route_name: this.$route.name,
            tracking_id: localStorage.getItem('tracking_id'),
            type: 'click',
            target_id: event.target.closest('[id]').id,
            target_tag_name: event.target.tagName,
            target_inner_html: event.target.innerHTML.trim(),
            meta: {
              client_x: event.clientX,
              client_y: event.clientY,
              target: event.target,
              // closest_parent_with_id: event.target.closest('[id]'),
            },
          }

          this.$http
            .post(`/api/page-activity-tracker`, { form_data })
            .then(response => {
              const { success, output, message } = response.data
            })
            .catch(error => {
              //
            })
        }, 500))
      }, 50)
    },

    trackPageVisit() {
      // TODO: prevent submit and creating newsession if from logout

      let utm_details = {}
      if (
        this.$isNotEmpty(localStorage.getItem('utm_source'))
        || this.$isNotEmpty(localStorage.getItem('utm_medium'))
        || this.$isNotEmpty(localStorage.getItem('utm_campaign'))
      ) {
        utm_details = {
          utm_source: localStorage.getItem('utm_source'),
          utm_medium: localStorage.getItem('utm_medium'),
          utm_campaign: localStorage.getItem('utm_campaign'),
          timestamp: new Date(),
        }
      }
      const form_data = {
        page_url: this.$removeUtmQuery(window.location.href),
        route_name: this.$route.name,
        tracking_id: localStorage.getItem('tracking_id'),
        type: 'page_visit',
        utm_details,
        meta: {},
      }
  
      this.$http
        .post(`/api/page-activity-tracker`, { form_data })
        .then(response => {
          const { success, output, message } = response.data
          this.firstPageVisit = false
        })
        .catch(error => {
          //
        })
    },

    throttle(fn, delay) {
      let timer;
      return function() {
        if (!timer) {
          timer = setTimeout(() => {
            fn();
            timer = null;
          }, delay);
        }
      };
    }
  },
}
